import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

interface Props {
  headline: string
  description: string
}

const SectionOne: React.FC<Props> = ({ headline, description }) => {
  const [text, setText] = useState({ headline, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article css={[tw`mt-12 md:px-4`]}>
      <SectionHeading>{text?.headline}</SectionHeading>
      <p
        dangerouslySetInnerHTML={{ __html: text?.description }}
        css={[
          tw`text-base md:text-xl mt-5 max-w-4xl mx-8 md:mx-auto text-center`,
        ]}
      />
    </article>
  )
}

export default SectionOne
