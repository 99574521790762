import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
interface Props {
  content: string
  title: string
}

const PropertiesTitle: React.FC<Props> = ({ title, content }) => {
  const [text, setText] = useState({ title, content })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, content })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, content])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`bg-gradient-to-b from-[#96e3ff38] to-white pt-20 md:px-4`]}>
      <h1 css={[tw`text-3xl md:text-4xl font-bold text-center`]}>
        {text?.title}
      </h1>
      <p
        css={[
          tw`text-base md:text-xl mt-5 max-w-4xl mx-8 md:mx-auto text-center`,
        ]}
        dangerouslySetInnerHTML={{ __html: text?.content }}
      />
    </article>
  )
}

export default PropertiesTitle
