import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import { PropertiesCard } from "../../templates/templates"
import { CardLink } from "../Card"

interface Props {
  headline: string
  cards: Array<PropertiesCard>
}

const PropertiesCards: React.FC<Props> = ({ cards, headline }) => {
  const [text, setText] = useState({ headline })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article css={[tw`mt-20 max-w-7xl md:mx-auto`]}>
      <SectionHeading>{text?.headline}</SectionHeading>
      <div css={[tw`flex flex-wrap gap-5 justify-center mt-8 px-2 md:gap-3 lg:gap-5`]}>
        {cards?.map((item, index) => (
          <CardLink key={index} {...item} />
        ))}
      </div>
    </article>
  )
}

export default PropertiesCards
